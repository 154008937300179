<template>
  <div>
    <b-card>
      <validation-observer
        ref="familySettingsRules"
        tag="form"
      >
        <b-form>
          <b-row class="mt-2">
            <b-col
              cols="6"
            >
              <!-- RTL -->
              <b-form-group
                label="Use Family Form"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <b-form-checkbox
                  v-model="familySettings.use_family_form"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="6"
            >
              <b-form-group
                label="Family Form"
                label-for="family-form"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <v-select
                  v-model="familySettings.family_form_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="familyFormsList"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="!familySettings.use_family_form"
                  input-id="family-form"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              cols="12"
            >
              <!-- RTL -->
              <b-form-group
                label="Is Required For Applications"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <b-form-checkbox
                  v-model="familySettings.is_family_form_required_for_applications"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              cols="12"
            >
              <!-- RTL -->
              <b-form-group
                label="Allow to Create Multiple Parents"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <b-form-checkbox
                  v-model="familySettings.allow_to_create_multiple_parents"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              cols="12"
            >
              <!-- RTL -->
              <b-form-group
                label="Is Required Second Parent"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <b-form-checkbox
                  v-model="familySettings.is_required_second_parent"
                  :disabled="!familySettings.allow_to_create_multiple_parents"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col
              cols="12"
            >
              <!-- RTL -->
              <b-form-group
                label="Is Required Confirmation Family Form"
                label-cols-sm="6"
                label-cols-lg="3"
              >
                <b-form-checkbox
                  v-model="familySettings.is_required_confirmation_family_form"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- buttons -->
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mt-2 mr-1"
                :disabled="isLoading"
                @click="handleForm"
              >
                Save changes
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                :to="{ name: 'admin-program'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import useProgramFamilySetting from '@/views/admin/program/useProgramFamilySetting'

export default {
  name: 'ProgramTabFamilySettings',
  components: {
    // ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: {
    familySettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      familyFormsList: [],
      isLoading: false,
    }
  },
  setup() {
    const {
      saveFamilySettings,
    } = useProgramFamilySetting()

    return {
      saveFamilySettings,
    }
  },
  async created() {
    await this.prepareFieldsBySteps()
  },
  methods: {
    async prepareFieldsBySteps() {
      this.familyFormsList = await store.dispatch('app-program/fetchAllFamilyForms')
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.familySettingsRules.validate().then(success => {
          if (success) {
            this.saveFamilySettings(this.familySettings)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
.table-actions {
  width: 100px;
}
</style>
